






















































import { Component, Vue } from "vue-property-decorator";
import SeriousPose from "./../components/seriouspose.vue";

@Component({
  components: {
    SeriousPose,
  },
})
export default class Hero extends Vue {
  devAge = 0;
  devDOB = "1986/09/10";

  mounted(): void {
    this.getAge();
  }

  getAge(): void {
    let today = new Date();
    let birthDate = new Date(this.devDOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.devAge = age;
  }
}
