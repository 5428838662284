






































import { Component, Vue } from "vue-property-decorator";
import Papa from "papaparse";

@Component
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-this-alias*/
export default class Testimonials extends Vue {
  testimonialFileUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQauVlkTTFhRdMt2SxdoPKt7F2T6kDrUXU-vFmKpfZ347eKy9jOtmX3kZHAoGEAGqSFZy35hjFURNKX/pub?output=csv";
  testimonialItems = [];

  mounted(): void {
    this.buildTestimonial();
  }

  buildTestimonial(): void {
    let self = this;
    Papa.parse(this.testimonialFileUrl, {
      download: true,
      header: true,
      complete: function (results: any) {
        self.testimonialItems = results.data;
      },
      error: function () {
        self.testimonialItems = [];
      },
    });
  }
}
