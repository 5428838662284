



































































































































































import { Component, Vue } from "vue-property-decorator";
import Papa from "papaparse";

@Component
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-this-alias*/
export default class Portfolio extends Vue {
  portfolioFileUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-KkvborLW9I7snJwnlthEG1dtZRHJ8Xemho0-4rNHyrqM0W2LSiMXXxA2WitS7sqAAgn3PeTJmSgJ/pub?output=csv";
  portfolioItems = [];
  portfolioItemsMore = [];
  total = 0;
  processing = false;

  mounted(): void {
    this.buildPortfolio();
  }

  buildPortfolio(): void {
    let self = this;
    this.processing = true;
    Papa.parse(this.portfolioFileUrl, {
      download: true,
      header: true,
      complete: function (results: any) {
        self.processing = false;
        let temp = results.data.reverse();
        self.total = temp.length;
        self.portfolioItems = temp.slice(0, 3);
        self.portfolioItemsMore = temp.slice(3);
      },
      error: function () {
        self.processing = false;
        self.portfolioItems = [];
      },
    });
  }
}
