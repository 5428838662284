



































































































import { Component, Vue } from "vue-property-decorator";
import Papa from "papaparse";

@Component
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-this-alias*/
export default class Work extends Vue {
  workFileUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQxjmAU6YAPD322dXH6A2mzKdvLw24_kZXnY8AE6KbQw2sQqIIbOw3Y2xG6yScur3FDKePjlXoF6cff/pub?output=csv";
  workItems = [];
  processingWork = false;

  mounted(): void {
    this.buildWork();
  }

  buildWork(): void {
    this.processingWork = true;
    let self = this;
    Papa.parse(this.workFileUrl, {
      download: true,
      header: true,
      complete: function (results: any) {
        self.processingWork = false;
        self.workItems = results.data.reverse();
      },
      error: function () {
        self.processingWork = false;
        self.workItems = [];
      },
    });
  }
}
