














import { Component, Vue } from "vue-property-decorator";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Hero from "./components/Hero.vue";
import Portfolio from "./components/Portfolio.vue";
import Testimonials from "./components/Testimonials.vue";
import Skills from "./components/Skills.vue";
import Work from "./components/Work.vue";

@Component({
  components: {
    Header,
    Hero,
    Portfolio,
    Testimonials,
    Skills,
    Work,
    Footer,
  },
})
export default class App extends Vue {}
