









































































import { Component, Vue } from "vue-property-decorator";
import CasualPose from "./../components/casualpose.vue";
import Papa from "papaparse";

@Component({
  components: {
    CasualPose,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-this-alias*/
export default class Skills extends Vue {
  hardFileUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vTlSvwrqh2wVmCX3OEkZlFnN8u1QeLJ-5vHKodqNERT9y9WRMBUnKyJTORCJy-Bv61_Yn3dgWpnqpcx/pub?output=csv";
  hardSkills = [];
  softFileUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vTiJX3PyxtJTgt34vTXmmbVx0Th08Nm7mmrUxp9zBPOYEeh1C1o_YSGPgBW7eGqaq7g3ZSClb76aSY8/pub?output=csv";
  softSkills = [];
  processingHard = false;
  processingSoft = false;

  mounted(): void {
    this.buildHard();
    this.buildSoft();
  }

  buildHard(): void {
    this.processingHard = true;
    let self = this;
    Papa.parse(this.hardFileUrl, {
      download: true,
      header: true,
      complete: function (results: any) {
        self.processingHard = false;
        self.hardSkills = results.data;
      },
      error: function () {
        self.processingHard = false;
        self.hardSkills = [];
      },
    });
  }

  buildSoft(): void {
    this.processingSoft = true;
    let self = this;
    Papa.parse(this.softFileUrl, {
      download: true,
      header: true,
      complete: function (results: any) {
        self.processingSoft = false;
        self.softSkills = results.data;
      },
      error: function () {
        self.processingSoft = false;
        self.softSkills = [];
      },
    });
  }
}
