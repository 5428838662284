var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"portfolio"}},[(!_vm.processing)?_c('div',{staticClass:"container"},[_vm._l((_vm.portfolioItems),function(item,index){return _c('div',{key:index,staticClass:"portfolio-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":'https://drive.google.com/uc?export=view&id=' + item.image_small,"alt":"Portfolio Image","srcset":'https://drive.google.com/uc?export=view&id=' +
              item.image_small +
              '  500w, https://drive.google.com/uc?export=view&id=' +
              item.image_big +
              '  700w',"sizes":"(max-width: 480px) 500px, 50vw","width":"100%","height":"380"}})]),_c('div',{staticClass:"col-12 col-md-6 d-flex"},[_c('div',{staticClass:"portfolio-text-content"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"portfolio-meta"},[_c('div',{staticClass:"portfolio-env"},[_vm._v(" "+_vm._s(item.env)+" ")]),_c('div',{staticClass:"portfolio-tech"},[_vm._v(" "+_vm._s(item.tech)+" ")]),(item.link)?_c('div',{staticClass:"portfolio-link"},[_c('a',{attrs:{"href":item.link,"target":"_blank","rel":"nofollow"}},[_vm._v(_vm._s(item.link_title))])]):_vm._e(),(!item.link && item.link_title)?_c('div',{staticClass:"portfolio-link broken-link"},[_vm._v(" "+_vm._s(item.link_title)+" ")]):_vm._e()])])])])])}),_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-portfolio'),expression:"'collapse-portfolio'"}],staticClass:"collapse-portfolio-trigger"},[_c('img',{attrs:{"src":require("./../assets/icon_expand.svg"),"alt":"Show More","width":"20","height":"20"}}),_c('span',{staticClass:"when-open"},[_vm._v("Show Less")]),_c('span',{staticClass:"when-closed"},[_vm._v("Show "+_vm._s(_vm.total - 3)+" More")])]),_c('b-collapse',{attrs:{"id":"collapse-portfolio"}},_vm._l((_vm.portfolioItemsMore),function(item,index){return _c('div',{key:index,staticClass:"portfolio-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('img',{staticClass:"img-fluid",attrs:{"src":'https://drive.google.com/uc?export=view&id=' +
                item.image_small,"alt":"Portfolio Image","srcset":'https://drive.google.com/uc?export=view&id=' +
                item.image_small +
                '  500w, https://drive.google.com/uc?export=view&id=' +
                item.image_big +
                '  700w',"sizes":"(max-width: 480px) 500px, 50vw"}})]),_c('div',{staticClass:"col-12 col-md-6 d-flex"},[_c('div',{staticClass:"portfolio-text-content"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"portfolio-meta"},[_c('div',{staticClass:"portfolio-env"},[_vm._v(" "+_vm._s(item.env)+" ")]),_c('div',{staticClass:"portfolio-tech"},[_vm._v(" "+_vm._s(item.tech)+" ")]),(item.link)?_c('div',{staticClass:"portfolio-link"},[_c('a',{attrs:{"href":item.link,"target":"_blank","rel":"nofollow"}},[_vm._v(_vm._s(item.link_title))])]):_vm._e(),(!item.link && item.link_title)?_c('div',{staticClass:"portfolio-link broken-link"},[_vm._v(" "+_vm._s(item.link_title)+" ")]):_vm._e()])])])])])}),0)],2):_c('div',{staticClass:"container"},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"portfolio-item"},[_vm._m(0,true)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"placeholder img-fluid",staticStyle:{"width":"100%","height":"380px"}})]),_c('div',{staticClass:"col-12 col-md-6 d-flex flex-column justify-content-evenly"},[_c('div',{staticClass:"placeholder placeholder-title"}),_c('div',[_c('div',{staticClass:"placeholder placeholder-paragraph"}),_c('div',{staticClass:"placeholder placeholder-paragraph"}),_c('div',{staticClass:"placeholder placeholder-paragraph narrower"})]),_c('div',[_c('div',{staticClass:"placeholder placeholder-paragraph narrower shorter"}),_c('div',{staticClass:"placeholder placeholder-paragraph narrower shorter"}),_c('div',{staticClass:"placeholder placeholder-paragraph narrower shorter"})])])])}]

export { render, staticRenderFns }