






































































import { Component, Vue } from "vue-property-decorator";
import PrivacyPolicy from "./PrivacyPolicy.vue";

@Component({
  components: {
    PrivacyPolicy,
  },
})
export default class App extends Vue {}
